export const PAGES = {
  ASSESSMENT: '/coaching/:libraryId',
  HOME: '/',
  PATIENT_HOME: '/patient-dashboard',
  SITEMAP: '/sitemap',
  STUDY_MANAGEMENT: '/study-management',
  REPORTS: '/reports',
  NOTES: '/notes',
  NOTIFICATIONS: '/notifications',
  SETTINGS: '/settings',
  VIDEO_CALL: '/video-call',
  VIDEO_CALL_GUEST: '/video-call/guest',
  CALL_NOTES: '/call-notes',
  SUPPORT: '/support',
  EMPTY: '/empty',
  EXAMPLE: '/Example',
  USERS: '/users',
  USER_PROFILE: '/users/profile/:userId',
  LIBRARY: '/library',
  MANAGER_USERS: '/users/manage',
  EXPORT_STUDY_DATA: '/export-study-data',
  EXPORT_REPORT: '/export-study-data?type=report',
  EXPORT_FITBIT: '/export-fitbit-data',
  MESSAGING: '/messaging',
  SCHEDULE: '/schedule',
  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  INTERVENTIONS: '/interventions',
  INTERVENTION_DETAIL: '/interventions/:interventionId',
  GRAPHQL: '/graphql',
  CREATE_STUDY: '/studies/create',
  CREATE_LIBRARY: '/library/create',
  UPDATE_LIBRARY: '/library/update/:libraryId',
  EDIT_STUDY: '/studies/edit/:studyId',
  AUTH_DIRECT_SIGNIN: '/auth/direct',

  // errors
  INTERNAL_SERVER: '/500',
  NOT_FOUND: '*',
};
