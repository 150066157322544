import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box, { BoxProps } from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EventNoteIcon from '@mui/icons-material/EventNote';
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip } from '@mui/material';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg';
import { ReactComponent as MessagesIcon } from '../../../assets/icons/messages.svg';
import { ReactComponent as ScheduleIcon } from '../../../assets/icons/schedule.svg';
import { PAGES } from '../../../constants';
import { Permissions, UserRoles } from '../../../constants/users';
import {
  useEmailsAnalytics,
  useMessagesAnalytics,
  useNotificationsAnalytics,
  useVoicesAnalytics,
} from '../../../contexts';
import { useCurrentUser, useUser } from '../../../states';
import { CompanyLogo } from '../../atoms';
import Row from './Row';
import { useStudyArmQuery, useStudyQuery } from '../../../graphql';
import { SelectStudySpaceModal } from '../../modals/users/SelectStudySpaceModal';

interface Properties extends BoxProps {
  onClose?: () => void;
}

export function SidebarLeftResearcher({ onClose, ...rest }: Properties): ReactElement {
  const { t } = usePreferredTranslation();
  const [isStudyModalOpen, setStudyModalOpen] = useState(false);
  const { studyId, checkUserPermission, checkUserIsSuperAdministrator, studyArmId, checkUserRole } =
    useUser();

  const { data: selectedStudy } = useStudyQuery({
    variables: {
      id: Number(studyId),
    },
    skip: !studyId,
  });
  const selectedStudyArm = useStudyArmQuery({
    variables: {
      id: Number(studyArmId),
    },
    skip: !studyArmId,
  });

  const sm = useMediaQuery('(min-width:750px)');

  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const messagesAnalytics = useMessagesAnalytics();
  const voicesAnalytics = useVoicesAnalytics();
  const emailsAnalytics = useEmailsAnalytics();
  const notificationsAnalytics = useNotificationsAnalytics();
  const studyRole = currentUser.data?.user?.roles?.find((sr) => sr.study.id === studyId);

  const handleNavigation = (path: string) => {
    if (onClose) {
      onClose();
    }

    setTimeout(() => {
      navigate(path);
    }, 100);
  };

  return (
    <>
      <Box
        role="navigation"
        aria-label="Sidebar Left"
        data-testid="sidebarLeftComponent"
        sx={{
          flexGrow: 1,
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'column',
        }}
        {...rest}
      >
        <Stack alignItems="flex-start" spacing={1} sx={{ py: 3, pl: 2 }}>
          {!sm && (
            <IconButton aria-label="Close" onClick={onClose} sx={{ p: 0, mb: 3 }}>
              <CloseIcon />
            </IconButton>
          )}{' '}
          <Box sx={{ flexGrow: 1 }}>
            <CompanyLogo />
          </Box>
        </Stack>

        <Typography
          variant="subtitle2"
          sx={{
            ml: 2,
            color: 'text.secondary',
          }}
        >
          {t('GENERAL')}
        </Typography>

        <List component="div" disablePadding sx={{ ml: 1 }}>
          <ListItem onClick={() => setStudyModalOpen(true)}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              justifyContent="space-between"
              sx={{
                width: '100%',
                sm: 2,
                border: 'solid',
                borderRadius: 3,
                borderColor: '#ccc',
                borderWidth: 1,
                px: 2,
                py: 1,
                cursor: 'pointer',
              }}
            >
              <Stack>
                <Tooltip title={selectedStudy?.study?.displayName} placement="top-end">
                  <Typography
                    variant="subtitle1"
                    color="primary.main"
                    sx={{
                      fontWeight: 600,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: '1',
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {selectedStudy?.study?.displayName}
                  </Typography>
                </Tooltip>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: 400,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '1',
                    WebkitBoxOrient: 'vertical',
                  }}
                  marginTop={-0.4}
                >
                  {selectedStudyArm.data?.studyArm?.name}
                </Typography>
              </Stack>
              <ExpandMoreIcon />
            </Stack>
          </ListItem>

          {checkUserPermission(currentUser.data?.user, Permissions.DASHBOARD) && (
            <Row
              onClick={handleNavigation}
              pathName={PAGES.HOME}
              label={t('Dashboard')}
              SvgIcon={HomeIcon}
            />
          )}

          {checkUserPermission(currentUser.data?.user, Permissions.STUDY_MANAGEMENT) && (
            <Row
              onClick={handleNavigation}
              pathName={PAGES.STUDY_MANAGEMENT}
              label={t('Study Management')}
              Icon={FolderCopyOutlinedIcon}
            />
          )}

          {checkUserPermission(currentUser.data?.user, Permissions.USER_MANAGEMENT) && (
            <Row
              onClick={handleNavigation}
              pathName={PAGES.MANAGER_USERS}
              label={t('User management')}
              Icon={GroupOutlinedIcon}
            />
          )}

          {checkUserPermission(currentUser.data?.user, Permissions.MESSAGING) && (
            <Row
              onClick={handleNavigation}
              pathName={PAGES.MESSAGING}
              label={t('Messaging')}
              SvgIcon={MessagesIcon}
            />
          )}

          {checkUserPermission(currentUser.data?.user, Permissions.SCHEDULING) && (
            <Row
              onClick={handleNavigation}
              pathName={PAGES.SCHEDULE}
              label={t('Schedule')}
              SvgIcon={ScheduleIcon}
            />
          )}

          {checkUserPermission(currentUser.data?.user, Permissions.LIBRARY) && (
            <Row
              onClick={handleNavigation}
              pathName={PAGES.LIBRARY}
              label={t('Library')}
              Icon={EventNoteIcon}
            />
          )}
        </List>

        <Box sx={{ mt: 'auto' }}>
          <Typography
            variant="subtitle2"
            sx={{
              ml: 2,
              mt: 5,
              color: 'text.secondary',
            }}
          >
            {t('OTHER')}
          </Typography>

          <List component="div" disablePadding sx={{ ml: 1 }}>
            {checkUserPermission(currentUser.data?.user, Permissions.NOTIFICATIONS) && (
              <Row
                onClick={handleNavigation}
                pathName={PAGES.NOTIFICATIONS}
                label={t('Notifications')}
                Icon={NotificationsActiveOutlinedIcon}
                renderBadge={
                  <Badge badgeContent={notificationsAnalytics.notViewedCount} color="error" />
                }
              />
            )}
            <Row
              onClick={handleNavigation}
              pathName={PAGES.SUPPORT}
              label={t('Help and support')}
              Icon={SupportOutlinedIcon}
            />
            <Row
              onClick={handleNavigation}
              pathName={PAGES.SETTINGS}
              label={t('Settings')}
              Icon={SettingsOutlinedIcon}
            />
          </List>

          <Divider />

          {currentUser.data?.user && (
            <ListItem
              data-testid="personalDetailsPageLinkFromSidebar"
              sx={{ color: 'inherit', textDecoration: 'none' }}
              disablePadding
            >
              <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: 2, my: 2 }}>
                <Avatar>{currentUser.data.user.name.charAt(0)}</Avatar>
                <Stack direction="column">
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    {currentUser.data.user.name}
                  </Typography>
                  {checkUserIsSuperAdministrator(currentUser.data.user) && (
                    <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
                      {currentUser.data.user.primaryRole?.name}
                    </Typography>
                  )}
                  {!checkUserIsSuperAdministrator(currentUser.data.user) && studyRole && (
                    <Typography variant="subtitle2" sx={{ fontWeight: 400 }}>
                      {studyRole.role.name}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </ListItem>
          )}
        </Box>
      </Box>
      <SelectStudySpaceModal open={isStudyModalOpen} onClose={() => setStudyModalOpen(false)} />
    </>
  );
}
