import { Box, BoxProps, Chip, Grid, Stack, SxProps, Typography } from '@mui/material';
import { ReactElement, useCallback, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';
import { ContentLoader } from '../atoms';
import { theme } from '../../theme';
import SubscribeButton from '../users/SubscribeButton';

interface Properties extends BoxProps {
  avatar?: ReactElement;
  loading?: boolean;
  title?: string;
  subtitle?: string | ReactElement;
  status?: string;
  idNumber?: string;
  children?: any;
  titleSx?: SxProps;
  showSubscription?: boolean;
  isSubscribed?: boolean;
  notificationPreferenceType?: string;
  userId?: string;
  refetch?: () => Promise<void>;
}

export function PageHeading({
  avatar,
  loading,
  status,
  idNumber,
  title,
  subtitle,
  children,
  titleSx,
  showSubscription = false,
  isSubscribed,
  notificationPreferenceType,
  userId,
  refetch,
  ...rest
}: Properties) {
  const { t } = usePreferredTranslation();
  const sm = useMediaQuery('(max-width:750px)');
  const [showSideNav, setShowSideNav] = useState(false);

  useCallback(() => {
    setShowSideNav(!showSideNav);
  }, [showSideNav, setShowSideNav]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          marginBottom: '10px',
          height: '40px',
        }}
        {...rest}
      >
        <Grid item gap={1} flexGrow={1}>
          <ContentLoader
            speed={2}
            width={200}
            height={90}
            viewBox="0 0 300 90"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="3" ry="3" width="150" height="30" />
            <rect x="0" y="40" rx="3" ry="3" width="300" height="20" />
          </ContentLoader>
        </Grid>
        {children && (
          <Grid item display="flex" alignItems="center" sx={{ width: 'auto' }}>
            <ContentLoader
              speed={2}
              width={150}
              height={90}
              viewBox="0 0 200 90"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="3" ry="3" width="200" height="40" />
            </ContentLoader>
          </Grid>
        )}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '18px',
        height: '40px',
      }}
      {...rest}
    >
      {!sm && avatar && (
        <Grid item mr={2}>
          {avatar}
        </Grid>
      )}
      <Grid item gap={1} flexGrow={1}>
        <Box>
          <Stack direction="row" alignItems="center">
            <Typography variant="h6" mr={2} sx={titleSx}>
              {title}
            </Typography>
            {idNumber && (
              <Chip
                label={idNumber}
                variant="filled"
                size="small"
                sx={{
                  borderRadius: 2,
                  background: theme.palette.primary.main,
                  fontWeight: 500,
                  color: 'white',
                  mr: 1,
                }}
              />
            )}
            {status && (
              <Chip
                label={status?.replaceAll('_', ' ')}
                variant="outlined"
                size="small"
                sx={{ borderRadius: 2, textTransform: 'capitalize', fontWeight: 500, mr: 1 }}
              />
            )}
            {showSubscription && (
              <SubscribeButton
                userId={userId}
                isSubscribed={isSubscribed}
                notificationPreferenceType={notificationPreferenceType}
                refetch={refetch}
              />
            )}
          </Stack>
          {subtitle &&
            (typeof subtitle === 'string' ? (
              <Typography variant="body2">{subtitle}</Typography>
            ) : (
              subtitle
            ))}
        </Box>
      </Grid>
      {children && (
        <Grid item display="flex" alignItems="center" sx={{ width: 'auto' }}>
          {children}
        </Grid>
      )}
    </Box>
  );
}
