import React, { useCallback, useState } from 'react';
import Calendar from 'react-calendar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Paper, Popover } from '@mui/material';
import { TOptions } from 'i18next';
import { usePreferredTranslation } from '../../../../hooks/usePreferredTranslation';
import { dayjs } from '../../../../utils/dayjs';
import { CoachingEventsQuery } from '../../../../graphql';
import { EventStatuses } from './type';
import { getLowestPriorityStatus } from './utils';

const getDateLabel = (date: Date, t: (key: string, options?: TOptions | undefined) => string) => {
  return dayjs().isSame(date, 'day') ? t('Today') : dayjs(date).format('MMM D, YYYY');
};

interface CoachingDatePickerProperties {
  selectedDate: Date;
  loading: boolean;
  handleChangeDate: (date: any) => void;
  coachingEvents: CoachingEventsQuery | undefined;
}

const TILE_CLASSES = {
  [EventStatuses.COMPLETED]: 'title__completed',
  [EventStatuses.INCOMPLETE]: 'title__incomplete',
  [EventStatuses.STARTED]: 'title__attempted',
};

const CoachingDatePicker: React.FC<CoachingDatePickerProperties> = ({
  handleChangeDate,
  selectedDate,
  coachingEvents,
  loading,
}) => {
  const { t, i18n } = usePreferredTranslation();
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleDateChange = useCallback(
    (date: any) => {
      handleChangeDate(date);
      handleClose();
    },
    [handleChangeDate],
  );

  const open = Boolean(anchorElement);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        onClick={handleClick}
        variant="text"
        disabled={loading}
        sx={{
          bgcolor: 'white',
          color: 'black',
          fontWeight: 400,
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {getDateLabel(selectedDate, t)}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Paper sx={{ p: 1 }}>
          <Calendar
            locale={i18n.language}
            onChange={handleDateChange}
            showNeighboringMonth={false}
            value={selectedDate}
            className="coaching-date-picker"
            tileClassName={({ date }) => {
              if (dayjs(date).isAfter(dayjs())) {
                return 'title__future';
              }

              const coachingEventsByDate = coachingEvents?.coachingEvents?.filter((coaching) => {
                return dayjs(date).isSame(coaching.start, 'day');
              });

              if (coachingEventsByDate && coachingEventsByDate?.length > 0) {
                if (coachingEventsByDate?.length === 1) {
                  const status = coachingEventsByDate[0]?.status as EventStatuses;
                  return `title__single ${TILE_CLASSES[status]}`;
                }

                const lowestPriorityStatus = getLowestPriorityStatus(coachingEventsByDate);

                return `title__future ${TILE_CLASSES[lowestPriorityStatus as EventStatuses]}`;
              }

              return 'title__disabled';
            }}
          />
        </Paper>
      </Popover>
    </div>
  );
};

export default CoachingDatePicker;
