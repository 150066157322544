import Box from '@mui/material/Box';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {
  Chip,
  CircularProgress,
  Grid,
  ListItemButton,
  Paper,
  Tab,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import _ from 'lodash';
import { useState } from 'react';
import { dayjs } from '../../../utils/dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { usePreferredTranslation } from '../../../hooks/usePreferredTranslation';
import { FORMATS } from '../../../constants';
import { ASSESSMENT, DEFAULT, IVR, JOURNAL } from '../../../constants/library';
import {
  AssessmentQuestionEntity,
  LibraryEntity,
  useAssessmentResponsesQuery,
  useJournalLazyQuery,
} from '../../../graphql';
import { useCurrentUser } from '../../../states';
import { useActiveJournal } from '../../../states/journal/useActiveJournal';
import { theme } from '../../../theme';
import { formatTimezone } from '../../../utils';
import { ContentLoader, Tabs } from '../../atoms';
import JournalModal from '../../journaling/journal/JournalModal';
import Answer from './Answer';


dayjs.extend(utc);
dayjs.extend(timezone);

interface Properties {
  userId: number;
}
const GREEN = '#43A047';
const DARK_GREEN = '#1B5E20';

const RED = '#E53935';
const DARK_RED = '#B71C1C';

const ALL_LIBRARIES = [IVR.value, ASSESSMENT.value, JOURNAL.value, DEFAULT.value];

const Assessments = ({ userId }: Properties) => {
  const { t } = usePreferredTranslation();
  const [selectedId, setSelectedId] = useState<string>('');
  const [assessmentTypesToShow, setAssessmentTypesToShow] = useState<string[]>(ALL_LIBRARIES);
  const { data: userData } = useCurrentUser();
  const userTimezone = formatTimezone(userData?.user?.timezone);

  const { setActiveJournal, setActiveDate, activeJournal } = useActiveJournal();

  // fetch assessment responses
  const {
    data: assessmentResponsesData,
    loading,
    refetch,
  } = useAssessmentResponsesQuery({
    fetchPolicy: 'network-only',
    variables: {
      where: {
        userId,
      },
    },
  });

  const [fetchJournal, { loading: isJournalLoading }] = useJournalLazyQuery();

  const onViewAssessment = async (assessmentId: string, type: string) => {
    if (type === 'JOURNAL') {
      const selectedJournalResponse = assessmentResponsesData?.assessmentResponses.items.find(
        (item) => item.id === assessmentId,
      );
      const journal = selectedJournalResponse?.library as LibraryEntity;

      const { data: libraryData } = await fetchJournal({
        variables: {
          where: {
            id: Number(journal.id),
          },
        },
      });


      const library = libraryData?.libraries?.items?.[0] as LibraryEntity;


      const activeDate = selectedJournalResponse?.answerDate || '';
  
      // Parse the date as UTC and retain it without applying local timezone offsets
      const parsedDate = dayjs.utc(activeDate).startOf('day');
  
      // Create a new Date object using the year, month, and day from the parsed UTC date
      const correctedDate = new Date(
        parsedDate.year(),
        parsedDate.month(),
        parsedDate.date()
      );
  

      setActiveDate(correctedDate);
      setActiveJournal(library);
      setSelectedId('');
    } else {
      setSelectedId(assessmentId);
    }
  };

  const selectedResponse = assessmentResponsesData?.assessmentResponses.items.find(
    (item) => item.id === selectedId,
  );


  // const { data: notesData, refetch: refetchNotes } = useNotesQuery({
  //   fetchPolicy: 'network-only',
  //   skip: !selectedResponse?.id,
  //   variables: {
  //     user: userId,
  //     where: {
  //       assessmentResponseId: Number(selectedResponse?.id),
  //     },
  //   },
  // });

  if (assessmentResponsesData?.assessmentResponses.items.length === 0) {
    return <Box />;
  }

  const assessments = assessmentResponsesData?.assessmentResponses.items
    .filter((a) => assessmentTypesToShow.includes(a.library.type || ''))
    .map((assessmentResponse) => {
      const type = (
        assessmentResponse.library.type === 'assessment'
          ? 'coaching'
          : assessmentResponse.library.type || 'coaching'
      ).toUpperCase();
      const { skippedQuestionsBySystemCount, skippedQuestionCount, preIVRQuestionsRequired } =
        assessmentResponse;

      const typeColor = type === 'JOURNAL' ? '#039BE5' : type === 'IVR' ? '#5E35B1' : '#EEEEEE';
      const typeBackgroundColor = type === 'JOURNAL' || type === 'IVR' ? 'white' : 'unset';

      const answers = _.uniqBy(assessmentResponse.answers, 'question.id');
      const answeredAnswersCount = answers.filter(
        (a) => !(!a.value && (!a.values?.length || a.values?.length === 0)),
      )?.length;

      let latestUpdatedAt = answers?.[0]?.updatedAt;
      for (let index = 1; index < answers.length; index += 1) {
        if (!answers[index]) continue;

        if (dayjs(answers[index]?.updatedAt).isAfter(dayjs(latestUpdatedAt))) {
          latestUpdatedAt = answers[index]?.updatedAt;
        }
      }
      const questions = assessmentResponse.library.questions as AssessmentQuestionEntity[];

      const totalQuestions = Number(questions?.length);
      const preIVRQuestionsSkippedBySystemCount = preIVRQuestionsRequired
        ? 0
        : questions.filter((q) => q?.category?.name === 'PRO-CTCAE - Pre-IVR').length;
      const completed =
        answeredAnswersCount + Number(skippedQuestionCount) >=
        totalQuestions -
          Number(skippedQuestionsBySystemCount) -
          Number(preIVRQuestionsSkippedBySystemCount);
      const answeredOnTime = !dayjs(assessmentResponse.createdAt).isAfter(
        dayjs(assessmentResponse.answerDate).endOf('day'),
      );

      const isCompleted = type === 'JOURNAL' ? true : completed;
      const label = isCompleted
        ? `${t('Completed')} ${answeredOnTime ? t('On Time') : t('Late')}`
        : t('Incomplete');
      const Icon = isCompleted ? CheckIcon : CloseIcon;

      return {
        id: assessmentResponse.id,
        type,
        typeColor,
        typeBackgroundColor,
        completed: isCompleted,
        label,
        answeredAnswersCount,
        skippedQuestionsBySystemCount:
          Number(skippedQuestionsBySystemCount) + preIVRQuestionsSkippedBySystemCount,
        skippedQuestionCount,
        libraryTitle: assessmentResponse.library.title,
        Icon,
        answers,
        libraryQuestions: assessmentResponse.library.questions,
        createdAt: assessmentResponse.createdAt,
        updatedAt: latestUpdatedAt,
        totalQuestions,
        libraryId: assessmentResponse.library.id,
        answerDate: assessmentResponse.answerDate,
      };
    });

  const filteredAssessments = assessments?.reduce<NonNullable<typeof assessments>>(
    (accumulator, assessment) => {
      // Exclude 'JOURNAL' type directly
      if (assessment.type !== 'JOURNAL') {
        accumulator.push(assessment);
        return accumulator;
      }

      const existingIndex = accumulator.findIndex(
        (a) =>
          a.type === 'JOURNAL' &&
          a.libraryId === assessment.libraryId &&
          a.answerDate === assessment.answerDate,
      );

      // If it doesn't exist, add it to the accumulator
      if (existingIndex === -1) {
        accumulator.push(assessment);
        return accumulator;
      }

      // If it exists, compare the 'createdAt' field and keep the latest
      if (accumulator[existingIndex]?.createdAt && assessment.createdAt) {
        const existingDate = new Date(accumulator[existingIndex]?.createdAt || '');
        const newDate = new Date(assessment.createdAt);
        if (existingDate < newDate) {
          accumulator[existingIndex] = assessment;
        }
      }

      return accumulator;
    },
    [],
  );

  return (
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
        <Tabs
          variant="standard"
          value={
            assessmentTypesToShow.filter((t) => !!t).length === 1
              ? assessmentTypesToShow[0]
              : assessmentTypesToShow
          }
          textColor="inherit"
          indicatorColor="secondary"
          onChange={(event, value) => {
            if (value === 'assessment') setAssessmentTypesToShow(['assessment', '']);
            else setAssessmentTypesToShow(Array.isArray(value) ? value : [value]);
            setSelectedId('');
          }}
          sx={{
            '& .MuiTabs-scroller': {
              textAlign: 'left',
            },
          }}
        >
          {[
            {
              label: 'All',
              value: ALL_LIBRARIES,
            },
            ASSESSMENT,
            JOURNAL,
            IVR,
          ].map(({ label, value }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tab key={index} value={value} label={label} />
          ))}
        </Tabs>
        <Box
          sx={{
            alignSelf: 'flex-end',
            alignItems: 'center',
            background: 'white',
            display: 'flex',
            margin: 1,
            borderRadius: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              margin: 1,
              mr: 0.5,
            }}
          >
            {t('COMPLETED')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              background: alpha(GREEN, 0.25),
              color: DARK_GREEN,
              pl: 0.5,
              pr: 0.5,
              borderRadius: 1,
            }}
          >
            {filteredAssessments?.filter((a) => a.completed).length}
          </Typography>
          <Box sx={{ height: 15, width: 1.5, background: grey[400], ml: 1, mr: 1 }} />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              margin: 1,
              mr: 0.5,
              ml: 0,
            }}
          >
            {t('INCOMPLETE')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
              background: alpha(RED, 0.25),
              color: DARK_RED,
              mr: 1,
              pl: 0.5,
              pr: 0.5,
              borderRadius: 1,
            }}
          >
            {filteredAssessments?.filter((a) => !a.completed).length}
          </Typography>
        </Box>
      </Box>
      <Paper
        data-testid="participantAssessmentHistory"
        sx={{
          flexGrow: 1,
        }}
      >
        <>
          {loading && (
            <ContentLoader
              speed={2}
              width="99%"
              viewBox="0 0 3000 510"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="10" y="0" rx="3" ry="3" width="100%" height="150" />
              <rect x="10" y="180" rx="3" ry="3" width="80%" height="150" />
              <rect x="10" y="360" rx="3" ry="3" width="90%" height="150" />
            </ContentLoader>
          )}

          <Grid container sx={{ flexGrow: 1, height: '100%' }}>
            <Grid item xs={selectedResponse ? 4 : 12} sx={{ height: 'inherit', overflow: 'auto' }}>
              <Box
                sx={{
                  textAlign: 'left',
                  padding: 0,
                  flexGrow: 1,
                  overflow: 'auto',
                  height: 'inherit',
                }}
              >
                {filteredAssessments?.map(
                  (
                    {
                      id,
                      type,
                      typeColor,
                      typeBackgroundColor,
                      completed,
                      label,
                      libraryTitle,
                      Icon,
                      answeredAnswersCount,
                      updatedAt,
                      skippedQuestionCount,
                      skippedQuestionsBySystemCount,
                      totalQuestions,
                    },
                    index,
                  ) => {
                    const isJournal = type === 'JOURNAL';

                    return (
                      <ListItemButton
                        key={index}
                        sx={{
                          py: 2,
                          width: '100%',
                          borderBottom:
                            index < filteredAssessments.length - 1 ? '1px solid' : 'none',
                          borderColor: theme.palette.divider,
                        }}
                        onClick={() => onViewAssessment(id, type)}
                      >
                        <Stack sx={{ width: '100%' }}>
                          <Box
                            sx={{
                              mb: 1,
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              flex: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <Box
                                sx={{
                                  background: completed ? GREEN : RED,
                                  borderRadius: 10,
                                  marginRight: 1,
                                  height: 25,
                                  width: 25,
                                }}
                              >
                                <Icon sx={{ color: 'white' }} />
                              </Box>

                              <Typography
                                sx={{
                                  color: completed ? GREEN : RED,
                                  fontWeight: 600,
                                  fontSize: 14,
                                }}
                              >
                                {label}
                              </Typography>
                            </Box>
                            <Box sx={{ mb: 1, justifySelf: 'flex-end', alignSelf: 'flex-end' }}>
                              <Chip
                                size="small"
                                label={type}
                                variant="outlined"
                                sx={{
                                  borderRadius: 2,
                                  mr: 1,
                                  fontWeight: 600,
                                  border: 'none',
                                  backgroundColor: typeColor,
                                  color: typeBackgroundColor,
                                }}
                              />
                            </Box>
                          </Box>

                          <Typography sx={{ fontWeight: 600, mb: 1 }}>{libraryTitle}</Typography>

                          <Typography color="GrayText" component="span" variant="body2">
                            {!isJournal && (
                              <>
                                {Number(answeredAnswersCount)}/{Number(totalQuestions || 0)}{' '}
                                {t('answered')}
                                {Number(skippedQuestionCount) > 0
                                  ? `, ${skippedQuestionCount} ${t('skipped')}`
                                  : ''}
                                {Number(skippedQuestionsBySystemCount) > 0
                                  ? `, ${skippedQuestionsBySystemCount} ${t('not required')}`
                                  : ''}
                                <br />
                              </>
                            )}
                            {dayjs(updatedAt).tz(userTimezone).format(FORMATS.DATE)}
                          </Typography>
                        </Stack>
                      </ListItemButton>
                    );
                  },
                )}
              </Box>
            </Grid>
            {selectedResponse && (
              <Grid
                item
                xs={8}
                sx={{
                  flexGrow: 1,
                  height: 'inherit',
                  border: '1px solid',
                  borderTop: 'none',
                  borderBottom: 'none',
                  borderColor: (theme) => theme.palette.divider,
                }}
              >
                {/* {(notesData?.notes?.count || 0) > 0 && (
                  <Box
                    sx={{
                      p: 2,
                      flexGrow: 1,
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, fontSize: 20 }}>{t('Notes')}</Typography>
                    {notesData?.notes.items.map((note) => {
                      return (
                        <Box
                          key={note.id}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderBottom: `1px solid ${theme.palette.divider}`,
                            mt: 0.5,
                            pb: 1,
                          }}
                        >
                          {note.description && (
                            <Typography
                              fontWeight={400}
                              sx={{ mb: 1, alignSelf: 'flex-end', fontSize: 14 }}
                            >
                              {note.description} at {dayjs(note.updatedAt).format(FORMATS.DATE)} (
                              {dayjs().to(dayjs(note.updatedAt))})
                            </Typography>
                          )}
                          <AddNote
                            noteId={note.id}
                            userId={userId}
                            note={note}
                            open
                            onNoteUpdate={() => {
                              refetchNotes();
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                )} */}
                <Box
                  sx={{
                    p: 2,
                    flexGrow: 1,
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: 20 }}>{t('Questions')}</Typography>

                  {_.uniqBy(selectedResponse.answers, 'question.id')
                    .sort((a1, a2) =>
                      Number(a1.question?.order) > Number(a2.question?.order) ? 1 : -1,
                    )
                    .map((answer) => (
                      <Answer
                        key={answer.id}
                        answer={answer}
                        assessmentResponseId={selectedResponse.id}
                        onAnswerUpdate={refetch}
                      />
                    ))}
                </Box>
              </Grid>
            )}
          </Grid>
        </>
      </Paper>
      <JournalModal open={!!activeJournal} userId={userId} />
      {isJournalLoading && (
        <CircularProgress
          sx={{
            position: 'fixed',
            top: '45%',
            zIndex: 1000,
            alignSelf: 'center',
          }}
        />
      )}
    </Box>
  );
};

export default Assessments;
