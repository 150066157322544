import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import NumbersIcon from '@mui/icons-material/Numbers';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LanguageIcon from '@mui/icons-material/Language';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PhoneIcon from '@mui/icons-material/Phone';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { MuiTelInputInfo } from 'mui-tel-input';
import { toast } from 'react-toastify';
import { dayjs } from '../../utils/dayjs';
import {
  PhoneNumberInputType,
  UserProfileDetailsInputType,
  useUpdateUserProfileInfoDetailsMutation,
} from '../../graphql';
import ProfileInfoEditableField, { ProfileInfoPhoneNumbersField } from './ProfileInfoEditableField';
import AutoCompleteClinicIds from './AutoCompleteClinicIds';
import { DateField, TimeField } from '../atoms';
import { theme } from '../../theme';
import { usePreferredTranslation } from '../../hooks/usePreferredTranslation';

interface Properties {
  canEdit: boolean;
  isAdmin: boolean;
  loading: boolean;
  userId: string | undefined;
  email: string | undefined | null;
  studyArm: string | undefined | null;
  isVerified: boolean;
  userProfileInfoDetails: UserProfileDetailsInputType;
  refetch: () => Promise<void>;
}

type UserInfoState = UserProfileDetailsInputType & {
  email: string;
  studyArm: string;
};

const iconStyle = {
  fontSize: '1.5rem',
  color: theme.palette.primary.main,
};

export default function ParticipantProfileInformation({
  canEdit,
  isAdmin,
  refetch,
  loading,
  userProfileInfoDetails,
  email,
  studyArm,
  userId,
  isVerified,
}: Properties) {
  const { t } = usePreferredTranslation();
  const [isEditing, setIsEditing] = useState(false);

  const [userInfo, setUserInfo] = useState<UserInfoState>({
    name: userProfileInfoDetails?.name || '',
    email: email || '',
    idNumber: userProfileInfoDetails?.idNumber,
    address: userProfileInfoDetails?.address,
    age: userProfileInfoDetails?.age,
    sexualOrientation: userProfileInfoDetails?.sexualOrientation,
    treatments: userProfileInfoDetails?.treatments,
    familyMembers: userProfileInfoDetails?.familyMembers,
    clinics: userProfileInfoDetails?.clinics,
    phoneNumbers: userProfileInfoDetails?.phoneNumbers,
    clinicId: userProfileInfoDetails?.clinicId,
    preferredLanguage: userProfileInfoDetails?.preferredLanguage,
    preferredTime: userProfileInfoDetails?.preferredTime,
    startDate: userProfileInfoDetails?.startDate
      ? new Date(userProfileInfoDetails?.startDate)
      : null,
    studyArm: studyArm || '',
    zipCode: userProfileInfoDetails?.zipCode,
  });

  const [updateProfileInfo, { loading: updateProfileInfoLoading }] =
    useUpdateUserProfileInfoDetailsMutation();

  const validateForm = () => {
    const formErrors = [];
    if (!userInfo.name || userInfo.name?.length < 3) {
      formErrors.push('Please enter a valid Name');
    }

    if (formErrors.length > 0) {
      toast.error(
        <Box>
          Please fix the following errors
          <ul>
            {formErrors.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        </Box>,
      );
    }

    return formErrors.length === 0;
  };

  const onSave = useCallback(async () => {
    if (!validateForm()) {
      return;
    }

    const { errors } = await updateProfileInfo({
      variables: {
        id: Number(userId),
        data: {
          name: userInfo.name,
          idNumber: userInfo.idNumber,
          address: userInfo.address,
          age: userInfo.age,
          sexualOrientation: userInfo.sexualOrientation,
          treatments: userInfo.treatments,
          familyMembers: userInfo.familyMembers,
          clinics: userInfo.clinics,
          phoneNumbers: userInfo.phoneNumbers,
          clinicId: userInfo.clinicId,
          preferredLanguage: userInfo.preferredLanguage,
          startDate: userInfo.startDate,
          assignedStaff: userProfileInfoDetails.assignedStaff,
          preferredTime: userInfo.preferredTime,
          zipCode: userInfo.zipCode,
        },
      },
    });

    if (errors) {
      const [error] = errors;
      toast.error(
        error?.message || 'An error occurred while updating user information. Please try again.',
      );

      return;
    }

    await refetch();
    setIsEditing(false);
    toast.success(t('Successfully updated profile information.'));
  }, [userId, userInfo, refetch, updateProfileInfo, userProfileInfoDetails.assignedStaff]);

  const updateUserState = (key: keyof UserInfoState, value: any) => {
    setUserInfo((previous) => ({ ...previous, [key]: value }));
  };

  const updatePreferredTime = (value: string) => {
    const parsedTime = dayjs(value);

    setUserInfo((previous) => ({
      ...previous,
      preferredTime: parsedTime.isValid()
        ? `${dayjs(value).hour()}:${dayjs(value).minute()}`
        : null,
    }));
  };

  const handleAddAnotherNumberForCall = useCallback((type: string) => {
    setUserInfo((previous) => ({
      ...previous,
      phoneNumbers: [...previous.phoneNumbers, { country: {}, type }],
    }));
  }, []);

  const onPhoneNumberChange = useCallback(
    (value: PhoneNumberInputType, index?: number) => {
      if (!index) return;

      const newPhoneNumbers = [...userInfo.phoneNumbers];
      newPhoneNumbers[index] = value;
      updateUserState('phoneNumbers', newPhoneNumbers);
    },
    [userInfo.phoneNumbers],
  );

  const handleRemoveNumberForCall = useCallback(
    (currentIndex: number) => {
      const newAdditionalNumbersForCall = [...userInfo.phoneNumbers];
      newAdditionalNumbersForCall.splice(currentIndex, 1);
      updateUserState('phoneNumbers', newAdditionalNumbersForCall);
    },
    [userInfo.phoneNumbers],
  );

  const setAdditionalNumberForCall = useCallback(
    (info: MuiTelInputInfo, currentIndex: number) => {
      const newPhoneNumbers = [...userInfo.phoneNumbers];
      newPhoneNumbers[currentIndex] = {
        country: {
          numberValue: info.numberValue,
          countryCode: info.countryCode,
          countryCallingCode: info.countryCallingCode,
          nationalNumber: info.nationalNumber,
        },
        type: newPhoneNumbers[currentIndex]?.type,
      };
      updateUserState('phoneNumbers', newPhoneNumbers);
    },
    [userInfo.phoneNumbers],
  );

  const renderPreferredTime = useCallback((value: string) => {
    let date = null;

    if (value) {
      date = new Date();
      const [hour, minute] = value.split(':');
      date.setHours(Number(hour));
      date.setMinutes(Number(minute));
    }

    return date || '';
  }, []);

  const isLoading = loading || updateProfileInfoLoading;

  return (
    <Paper
      sx={{ position: 'relative', p: 3, pb: 4, overflowX: 'auto' }}
      data-testid="participant-information-card"
    >
      <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'center', mb: 2 }}>
        <Typography
          component="p"
          flexGrow={1}
          sx={{
            fontSize: '1.25rem',
            fontWeight: 600,
          }}
        >
          {isAdmin ? t('admin') : t('participant')} {t('information')}
        </Typography>
        {canEdit &&
          (isEditing ? (
            <Button
              data-testid="info-save-button"
              disabled={isLoading}
              startIcon={
                updateProfileInfoLoading ? (
                  <CircularProgress size="1rem" />
                ) : (
                  <SaveAltOutlinedIcon />
                )
              }
              onClick={onSave}
            >
              {t('Save')}
            </Button>
          ) : (
            <Button
              data-testid="info-edit-button"
              startIcon={<EditOutlinedIcon />}
              onClick={() => setIsEditing(true)}
            >
              {t('Edit')}
            </Button>
          ))}
      </Stack>

      <Grid container spacing={3} rowGap={3}>
        <ProfileInfoEditableField
          isEditing={isEditing}
          label={t('profile.name')}
          value={userInfo.name}
          icon={<PersonOutlineIcon style={{ ...iconStyle, fontSize: '1.7rem' }} />}
        >
          <TextField
            disabled={isLoading}
            inputProps={{
              'data-testid': 'info-name',
            }}
            value={userInfo.name || ''}
            onChange={(event) => updateUserState('name', event.target.value)}
            fullWidth
          />
        </ProfileInfoEditableField>

        <ProfileInfoEditableField
          isEditing={isEditing}
          label={t('profile.email')}
          value={userInfo.email}
          icon={<MailOutlineIcon style={iconStyle} />}
        >
          <TextField
            disabled
            inputProps={{
              'data-testid': 'info-email',
            }}
            value={userInfo.email || ''}
            fullWidth
          />
        </ProfileInfoEditableField>

        {!isAdmin && (
          <ProfileInfoEditableField
            isEditing={isEditing}
            label={t('profile.studyArm')}
            value={userInfo.studyArm}
            icon={<CallSplitIcon style={iconStyle} />}
          >
            <TextField
              disabled
              inputProps={{
                'data-testid': 'info-study-arm',
              }}
              value={userInfo.studyArm || ''}
              fullWidth
            />
          </ProfileInfoEditableField>
        )}

        <ProfileInfoEditableField
          isEditing={isEditing}
          label={isAdmin ? t('profile.adminID') : t('profile.participantID')}
          value={userInfo.idNumber}
          icon={<NumbersIcon style={iconStyle} />}
        >
          <TextField
            disabled={isLoading}
            inputProps={{
              'data-testid': 'info-participant-id',
            }}
            placeholder={t('profile.enterParticipantId')}
            value={userInfo.idNumber || ''}
            onChange={(event) => updateUserState('idNumber', event.target.value)}
            fullWidth
          />
        </ProfileInfoEditableField>

        {!isAdmin && (
          <ProfileInfoEditableField
            isEditing={isEditing}
            label={t('profile.clinicID')}
            value={userInfo.clinicId}
            icon={<LocalHospitalIcon style={iconStyle} />}
          >
            <AutoCompleteClinicIds
              onChange={(clinicId) => updateUserState('clinicId', clinicId)}
              defaultValue={userInfo.clinicId || ''}
              disabled={isLoading}
            />
          </ProfileInfoEditableField>
        )}
        <ProfileInfoEditableField
          isEditing={isEditing}
          label={isAdmin ? t('profile.adminStartDate') : t('profile.participantStartDate')}
          value={userInfo.startDate?.toDateString()}
          icon={<CalendarTodayIcon style={iconStyle} />}
        >
          <DateField
            disabled={isLoading || isVerified}
            dataTestId="participant-start-date"
            value={userInfo.startDate?.toDateString() || ''}
            onChange={(date) => updateUserState('startDate', dayjs(date).toDate())}
            formSx={{ mt: -3 }}
          />
        </ProfileInfoEditableField>

        <ProfileInfoEditableField
          isEditing={isEditing}
          label={t('profile.preferredLanguage')}
          value={
            userInfo.preferredLanguage === 'English' ? `🇬🇧 ${t('English')}` : `🇪🇸 ${t('Spanish')}`
          }
          icon={<LanguageIcon style={iconStyle} />}
        >
          <Select
            value={userInfo.preferredLanguage}
            displayEmpty
            renderValue={
              !userInfo.preferredLanguage || userInfo.preferredLanguage === ''
                ? () => 'Select Preferred Language'
                : undefined
            }
            onChange={(event) => updateUserState('preferredLanguage', event.target.value)}
            fullWidth
            disabled={isLoading}
          >
            <MenuItem value="English">{t('English')}</MenuItem>
            <MenuItem value="Spanish">{t('Spanish')}</MenuItem>
          </Select>
        </ProfileInfoEditableField>

        <ProfileInfoEditableField
          isEditing={isEditing}
          label={t('profile.preferredTime')}
          value={
            userInfo.preferredTime
              ? dayjs(renderPreferredTime(userInfo.preferredTime || '')).format('hh:mm A')
              : ''
          }
          icon={<AccessTimeIcon style={iconStyle} />}
        >
          <TimeField
            disabled={isLoading || isVerified}
            value={renderPreferredTime(userInfo.preferredTime || '').toString()}
            onChange={updatePreferredTime}
            disableErrorState
          />
        </ProfileInfoEditableField>

        <ProfileInfoEditableField
          isEditing={isEditing}
          label={t('profile.age')}
          value={userInfo.age}
          icon={<LocalHospitalIcon style={iconStyle} />}
        >
          <TextField
            disabled={isLoading}
            placeholder={t('profile.enterAge')}
            type="number"
            inputProps={{
              'data-testid': 'info-age',
            }}
            value={userInfo.age || ''}
            fullWidth
            onChange={(event) => {
              const value = event.target.value.replace(/\D/g, '');
              if (value.length <= 3) {
                updateUserState('age', value);
              }
            }}
          />
        </ProfileInfoEditableField>

        <ProfileInfoEditableField
          isEditing={isEditing}
          label={t('profile.sex')}
          value={userInfo.sexualOrientation}
          icon={<CalendarTodayIcon style={iconStyle} />}
        >
          <Select
            value={userInfo.sexualOrientation}
            displayEmpty
            renderValue={
              !userInfo.sexualOrientation || userInfo.sexualOrientation === ''
                ? () => 'Select'
                : undefined
            }
            onChange={(event) => updateUserState('sexualOrientation', event.target.value)}
            fullWidth
            disabled={isLoading}
          >
            <MenuItem value="Male">{t('Male')}</MenuItem>
            <MenuItem value="Female">{t('Female')}</MenuItem>
          </Select>
        </ProfileInfoEditableField>

        <ProfileInfoEditableField
          isEditing={isEditing}
          label={t('profile.participantAddress')}
          value={userInfo.address}
          icon={<FmdGoodIcon style={iconStyle} />}
        >
          <TextField
            disabled={isLoading}
            placeholder={t('profile.enterAddress')}
            inputProps={{
              'data-testid': 'info-address',
            }}
            value={userInfo.address || ''}
            fullWidth
            onChange={(event) => updateUserState('address', event.target.value)}
          />
        </ProfileInfoEditableField>

        <ProfileInfoEditableField
          isEditing={isEditing}
          label={t('profile.zipCode')}
          value={userInfo.zipCode}
          icon={<FmdGoodIcon style={iconStyle} />}
        >
          <TextField
            disabled={isLoading}
            placeholder={t('profile.enterZipCode')}
            inputProps={{
              'data-testid': 'info-address',
            }}
            value={userInfo.zipCode || ''}
            onChange={(event) => updateUserState('zipCode', event.target.value)}
            fullWidth
          />
        </ProfileInfoEditableField>

        <ProfileInfoPhoneNumbersField
          label={t('Number for voice calls')}
          type="Mobile"
          addButtonLabel={t('Add voice number')}
          isEditing={isEditing}
          phoneNumbers={userInfo.phoneNumbers}
          loading={isLoading}
          onPhoneNumberChange={onPhoneNumberChange}
          setAdditionalNumberForCall={setAdditionalNumberForCall}
          handleRemoveNumberForCall={handleRemoveNumberForCall}
          handleAddAnotherNumberForCall={() => handleAddAnotherNumberForCall('Mobile')}
          icon={<PhoneIcon style={iconStyle} />}
        />

        <ProfileInfoPhoneNumbersField
          label={t('Number for SMS')}
          type="SMS"
          addButtonLabel={t('Add SMS phone')}
          isEditing={isEditing}
          phoneNumbers={userInfo.phoneNumbers}
          loading={isLoading}
          onPhoneNumberChange={onPhoneNumberChange}
          setAdditionalNumberForCall={setAdditionalNumberForCall}
          handleRemoveNumberForCall={handleRemoveNumberForCall}
          handleAddAnotherNumberForCall={() => handleAddAnotherNumberForCall('SMS')}
          icon={<PhoneIphoneIcon style={iconStyle} />}
        />
      </Grid>
    </Paper>
  );
}
